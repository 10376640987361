<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleClose"
      :width="width">
      <slot></slot>
      
      <span slot="footer" class="dialog-footer" v-if="!isHideFooter">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
export default {
data() {
    return {
      dialogVisible: true
    };
  },
  props: {
    title: {
      type: String,
      default: '详情'
    },
    width: {
      type: String,
      default: '60%'
    },
    injectData: {
      type: Object,
      default: () => {}
    },
    isHideFooter:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style>

</style>